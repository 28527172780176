





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { version_number } from '@/constants/version';

@Component
export default class ProgressIndicator extends Vue {
  versionNo = version_number;
}
