




/* eslint-disable no-console */
import { Vue, Component, Prop } from 'vue-property-decorator';
import mapboxgl from 'mapbox-gl';
import { mapMutations } from 'vuex';
import { Place } from '@/models/place';
import eventBus from '@/eventBus';
import store from '@/store';
import { MapboxOptions } from 'mapbox-gl/index';
import MapService from '@/services/mapService';
import { MAP_TOKEN } from '@/constants/const';

@Component({
  methods: mapMutations(['setPlaceList', 'setDataLoaded']),
})
export default class Map extends Vue {
  @Prop()
  readonly mapOptions!: Object;

  @Prop({
    type: Boolean,
    default: () => true,
  })
  readonly zoomControl!: Boolean;

  @Prop({
    type: Boolean,
    default: () => false,
  })
  readonly locationControl!: Boolean;

  options: MapboxOptions = {
    container: 'map',
    ...this.mapOptions,
  };

  mounted() {
    // ⚙️ CONFIG MAP
    mapboxgl.accessToken = MAP_TOKEN;
    let map = new mapboxgl.Map(this.$data.options);
    this.$store.dispatch('configMapbox', new MapService(map));
  }
}
