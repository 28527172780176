


















































































/* eslint-disable no-console */
import eventBus from '@/eventBus';
import store from '@/store';
import { Route } from 'vue-router';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Place } from '@/models/place';
import { Watch } from 'vue-property-decorator';
import ChipLabel from './ChipLabel.vue';
import place_types from '@/constants/placeType';
import kPlaceToTheme from '@/constants/placeToTheme';

@Component<Dock>({
  components: { ChipLabel },
})
export default class Dock extends Vue {
  place: Place = (this.$attrs.place as unknown) as Place;
  expanded: Boolean = false;
  shareMenu = false;
  copiedSnackbar = false;
  shareMenuItems = [
    {
      name: 'Facebook',
      icon: 'mdi-facebook',
      onClick: () =>
        this.sharePlace(
          `https://www.facebook.com/dialog/share?app_id=${this.facebookAppId}&display=popup&href=${this.currentLink}&redirect_uri=${this.currentLink}`,
        ),
    },
    {
      name: 'Copy to Clipboard',
      icon: 'mdi-clipboard-multiple-outline',
      onClick: () => this.copyToClipboard(this.currentLink),
    },
    // {
    //   name: 'S.M.S',
    //   icon: 'mdi-android-messages',
    //   onClick: () => (window.location.href = `sms:;body=${this.currentLink}`),
    // },
    // {
    //   name: 'Messenger',
    //   icon: 'mdi-facebook-messenger',
    //   onClick: () => {
    //     let link = `http://www.facebook.com/dialog/send?app_id=${this.facebookAppId}&link=${this.currentLink}&redirect_uri=${this.currentLink}`;
    //     this.sharePlace(link);
    //   },
    // },
    // {
    //   name: 'Tweet',
    //   icon: 'mdi-twitter',
    //   onClick: () => {
    //     let link = 'https://twitter.com/intent/tweet';
    //     this.sharePlace(link);
    //   },
    // },
  ];

  get lngLat() {
    return this.place.geometry.coordinates;
  }

  get phones() {
    return this.place.properties.phones;
  }

  get note() {
    return this.place.properties.note;
  }

  get currentLink() {
    return `https://du-map.web.app/place/${this.place.properties.id}`;
  }

  get facebookAppId() {
    return 2954507561282991;
  }

  getThemeOf(value: place_types) {
    return kPlaceToTheme[value];
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  copyToClipboard(text: string) {
    let copyArea = document.getElementById('copyarea') as any;
    copyArea.value = text;
    copyArea.focus();
    copyArea.style.display = 'inline-block';
    copyArea.select();
    copyArea.blur();

    let copySucceed = document.execCommand('copy');
    copyArea.style.display = 'none';

    if (copySucceed) {
      this.closeShareMenu();
      this.fireCopiedSnackBar();
    }
  }

  openShareMenu() {
    console.log(window.location.href);
    let nav = navigator as any;
    if (nav.share) {
      console.log('share menu available');
      (nav as any).share({
        title: 'Share ' + this.place.properties.name,
        url: window.location.href,
      });
    } else {
      console.log('share menu NOT available');
      this.shareMenu = true;
    }
  }

  closeShareMenu() {
    this.shareMenu = false;
  }

  fireCopiedSnackBar() {
    this.copiedSnackbar = true;
  }

  sharePlace(link: string) {
    window.open(link, 'newWin', 'width=500,height=500');
  }

  mounted() {
    // dispatch after mounted. need to wait for the mapbox to complete loading.
    store.dispatch('highLightPlace', store.getters.placeById(this.place.properties.id));
    // console.log(this.place);
  }

  destroyed() {
    store.dispatch('removeHighLight');
  }

  // @Watch('$route')
  // onRouteChange(to: Route, from: Route) {
  //   // update current place on route change
  //   let nextPlace: Place = store.getters.placeById(to.params.id);
  //   eventBus.$emit('highlightPlace', nextPlace);
  //   this.place = nextPlace;
  // }
}
