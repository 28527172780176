



































import { Vue, Component } from 'vue-property-decorator';
import store from '@/store';
import { mapState, mapGetters, mapActions } from 'vuex';
import Map from '@/components/Map.vue';
import ProgressIndicator from '@/components/ProgressIndicator.vue';
import AppBar from '@/components/AppBar.vue';
import OfflineDialog from '@/components/OfflineDialog.vue';
import NaviDrawer from '@/components/NaviDrawer.vue';
import Fab from '@/components/Fab.vue';
import { Place } from '@/models/place';
import eventBus from '@/eventBus';
import { App } from '../models/appState';

@Component({
  store,
  components: {
    Map,
    ProgressIndicator,
    AppBar,
    Fab,
    NaviDrawer,
    OfflineDialog,
  },
  computed: {
    ...mapState(['dataLoaded', 'searchClosed']),
    ...mapGetters(['isLoading', 'isLoaded', 'isOffline', 'isSearching']),
  },
  methods: {
    // ...mapActions(['openSearch', 'closeSearch']),
  },
})
export default class Home extends Vue {
  mapOptions = {
    style: 'mapbox://styles/akp101/cjxkkxwpc01x11cnur0aepitf/draft',
    center: [96.212739, 16.911199],
    zoom: 14.8,
    maxBounds: [
      [96.20043008891338, 16.899012663005408], // Southwest coordinates
      [96.22252355799174, 16.9239222243597], // Northeast coordinates
    ],
    bearing: -27.5, // rotation
    touchZoomRotate: true,
  };

  onLoaded(placeList: Array<Place>) {
    this.$store.commit('setPlaceList', placeList);
    this.$store.commit('setDataLoaded', true);
    this.$emit('loaded');
    // eslint-disable-next-line no-console
    console.log('Home: LOADED');
  }

  closeSearch() {
    store.dispatch('closeSearch');
  }

  reload() {
    location.reload();
  }

  closeDialog() {
    this.$router.replace('/');
  }
}
