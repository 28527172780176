










import { Vue, Component, Prop } from 'vue-property-decorator';
import kPlaceToTheme from '@/constants/placeToTheme';
import place_types from '../constants/placeType';
import Theme from '@/models/theme';

@Component
export default class ChipLabel extends Vue {
  @Prop({
    required: true,
  })
  readonly theme!: Theme;
  @Prop({
    required: true,
  })
  readonly value!: string;

  get formatedValue() {
    return this.value.length < 4 ? this.value.toUpperCase() : this.value;
  }
}
