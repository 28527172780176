


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Fab extends Vue {
  @Prop({
    default: 'primary',
  })
  color!: String;

  onClick() {
    this.$emit('click');
  }
}
