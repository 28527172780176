






















































import { Vue, Component } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import eventBus from '@/eventBus';
import { Place } from '@/models/place';
import PlaceIcon from '@/components/PlaceIcon.vue';
import Fuse from 'fuse.js';
// import gsap from 'gsap';

@Component({
  computed: {
    ...mapState(['drawer', 'searchClosed', 'installable', 'searchText']),
    ...mapGetters(['isSearching']),
  },
  methods: {
    ...mapActions(['openDrawer', 'installPWA', 'closeSearch']),
  },
  components: { PlaceIcon },
})
export default class AppBar extends Vue {
  fuse = new Fuse<Place, Fuse.FuseOptions<Place>>(Array.from(this.$store.state.map.places), {
    shouldSort: true,
    includeScore: false,
    includeMatches: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,

    keys: ['properties.name', 'properties.type', 'properties.buildingsString'],
  });

  clearSearch() {
    this.$store.dispatch('setSearch', '');
  }

  onClick(place: Place) {
    this.$store.dispatch('closeSearch');
    this.clearSearch();
    this.$router.replace(`/place/${place.properties.id}`);
  }

  onInput(value: any) {
    this.$store.dispatch('setSearch', value);
  }

  get searchIsBusy() {
    return (
      this.$store.getters.getSearchText &&
      this.$store.getters.getSearchText.length &&
      this.filteredPlaces.length
    );
  }

  get filteredPlaces() {
    return this.fuse.search<Array<Place>>(this.$store.getters.getSearchText || '');
  }

  get appBarWidth() {
    return this.$store.state.installable ? '156px' : '';
  }

  mounted() {
    // gsap.from('.list-item', {
    //   duration: 0.5,
    //   opacity: 0,
    //   y: 200,
    //   ease: 'power1',
    //   stagger: 0.1,
    // });
  }
}
